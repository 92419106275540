const isBigScreen = typeof window !== 'undefined' && window.innerHeight >= 900

export const MESSAGE_LIST_SLICE = isBigScreen ? 60 : 40
export const MESSAGE_LIST_VIEWPORT_LIMIT = MESSAGE_LIST_SLICE * 2

export const CHAT_HEIGHT_PX = 72
export const CHAT_LIST_SLICE = isBigScreen ? 30 : 25
export const CHAT_LIST_LOAD_SLICE = 100
export const SHARED_MEDIA_SLICE = 42
export const MESSAGE_SEARCH_SLICE = 42
export const GLOBAL_SEARCH_SLICE = 20
export const MEMBERS_SLICE = 30
export const MEMBERS_LOAD_SLICE = 200
export const PINNED_MESSAGES_LIMIT = 50
export const BLOCKED_LIST_LIMIT = 100
export const PROFILE_PHOTOS_LIMIT = 40
export const PROFILE_SENSITIVE_AREA = 500
export const COMMON_CHATS_LIMIT = 100
export const GROUP_CALL_PARTICIPANTS_LIMIT = 100
export const REACTION_LIST_LIMIT = 100
export const REACTION_UNREAD_SLICE = 100
export const MENTION_UNREAD_SLICE = 100

export const TOP_CHAT_MESSAGES_PRELOAD_LIMIT = 20

export const DEFAULT_VOLUME = 1
export const DEFAULT_PLAYBACK_RATE = 1

export const ANIMATION_LEVEL_MIN = 0
export const ANIMATION_LEVEL_MED = 1
export const ANIMATION_LEVEL_MAX = 2
export const ANIMATION_LEVEL_DEFAULT = ANIMATION_LEVEL_MAX

export const DEFAULT_MESSAGE_TEXT_SIZE_PX = 16
export const IOS_DEFAULT_MESSAGE_TEXT_SIZE_PX = 17
export const MACOS_DEFAULT_MESSAGE_TEXT_SIZE_PX = 15

export const DRAFT_DEBOUNCE = 10000 // 10s
export const SEND_MESSAGE_ACTION_INTERVAL = 3000 // 3s

// Screen width where Pinned Message / Audio Player in the Middle Header can be safely displayed
export const SAFE_SCREEN_WIDTH_FOR_STATIC_RIGHT_COLUMN = 1440 // px
// Screen width where Pinned Message / Audio Player in the Middle Header shouldn't collapse with ChatInfo
export const SAFE_SCREEN_WIDTH_FOR_CHAT_INFO = 1150 // px

export const MIN_SCREEN_WIDTH_FOR_STATIC_RIGHT_COLUMN = 1275 // px
export const MIN_SCREEN_WIDTH_FOR_STATIC_LEFT_COLUMN = 925 // px
export const MOBILE_SCREEN_MAX_WIDTH = 600 // px
export const MOBILE_SCREEN_LANDSCAPE_MAX_WIDTH = 950 // px
export const MOBILE_SCREEN_LANDSCAPE_MAX_HEIGHT = 450 // px

export const ANIMATION_END_DELAY = 100

export const FAST_SMOOTH_MAX_DISTANCE = 1500
export const FAST_SMOOTH_MIN_DURATION = 250
export const FAST_SMOOTH_MAX_DURATION = 600
export const FAST_SMOOTH_SHORT_TRANSITION_MAX_DISTANCE = 500 // px

export const LOCK_SCREEN_ANIMATION_DURATION_MS = 200

export const MENU_TRANSITION_DURATION = 200
export const SLIDE_TRANSITION_DURATION = 450

export let isRfqMode = false
export let isChatMode = false

export function setModeRfq(value: boolean) {
  isRfqMode = value
}

export function setModeChat(value: boolean) {
  isChatMode = value
}
